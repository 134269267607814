import React, { useRef, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { state } from '../store.js';
import Swiper from 'swiper';
import 'swiper/css';



export default function ChatListIntents(props) {
    const snap = useSnapshot(state);
    const chatListContainer = useRef(null);
    const { setOpen, desktopList, setDesktopList} = props;
    const [elementsTotal, setElementsTotal] = useState(0)


    const handleButtonClick = (action) => {
        /* if(window.innerWidth < 1140 ){
            setOpen(false)
        } else {
            setDesktopList(false)
        } */
        setDesktopList(false)

        state.session.actions.lockControls()
        state.session.actions.addNewMessage({"text": action.message}, 'user', 'waiting')
        setTimeout(() => {
            state.session.actions.addNewMessage(null, 'agent', 'loading')
        }, 500);
    };

    useEffect(() => {
        console.log(chatListContainer.current);
       /* if(chatListContainer.current && window.innerWidth >= 1140){ */
        if(chatListContainer.current ){
            const containerRect = chatListContainer.current.getBoundingClientRect();
            const containerWidth = chatListContainer.current.clientWidth - 150;

            // Otteniamo tutti gli elementi figli del contenitore
            const children = Array.from(chatListContainer.current.children);

            // Array per memorizzare gli elementi che sono fuori dalla vista
            const hiddenElements = children.filter(child => {
                const childRect = child.getBoundingClientRect();
                // Verifica se l'elemento è completamente fuori dai limiti del contenitore
                return (childRect.left + childRect.width > containerRect.left + containerWidth);
            });

            // Numero di elementi nascosti
            const hiddenCount = hiddenElements.length;

            // Rimuovi gli elementi nascosti
            hiddenElements.forEach(element => {
                element.style.display = 'none';
            });

            setElementsTotal(hiddenCount)
            console.log(hiddenCount);
        }
    }, [chatListContainer.current, window.innerWidth, snap.session.intents]);

    return (
        <>
           <div id="chatListIntents" ref={chatListContainer}>
                {snap.session.intents && snap.session.intents.map((intent, index) => (
                    <button 
                        key={index} 
                        onClick={() => handleButtonClick(intent)}
                    >
                        {intent.label}
                    </button>
                ))}
                {snap.session.intents && snap.session.intents.length > 0 &&
                    elementsTotal > 0 &&
                        <button 
                            className="toggle-mobile"
                            onClick={() => setDesktopList(!desktopList)}
                        >
                            
                            {desktopList ? "- Mostra meno" : "+ Mostra altro"}
                        </button>
                }
            </div>
        </>
    );
}
